@import "../../global.scss";

.gallery {
  padding-top: 100px;
  background-color: black;
  min-height: 100vh;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;

  .pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
    &:hover {
      filter: opacity(0.8);
    }
  }
  @include mobile {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}
.model {
  width: 100%;
  height: calc(100vh - 100px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transition: opacity 0.5s ease, visibility 0.5s ease,
    transform 0.6s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;

  &.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    svg {
      position: fixed;
      top: 10px;
      right: 10px;
      width: 2rem;
      height: 2rem;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.4);
      color: white;
      cursor: pointer;
    }
  }
  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
  }
}
