@import "../../global.scss";
.about {
  background-color: black;
  padding-top: 100px;
  color: white;
  display: flex;

  overflow: hidden;

  @include mobile {
    max-height: 100vh;
    padding-top: 100px;
    flex-direction: column;
    align-items: center;
  }

  .left {
    flex: 0.5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
      padding: 0;
      flex: 0.5;
    }
    .imgContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70%;

      @include mobile {
        display: flex;
      }

      img {
        float: left;
        max-height: 550px;
        max-width: 600px;
        height: auto;
        display: flex;
        @include mobile {
          height: 275px;
          width: 400px;
        }
        border-radius: 50px;
        animation: fade 5s infinite;

        @keyframes fade {
          0% {
            opacity: 0.2;
          }

          50% {
            opacity: 0.75;
          }
          100% {
            opacity: 1;
          }
        }
      }
    }
  }
  .right {
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    .desc {
      align-items: center;
      justify-content: center;
      opacity: 1;
      padding-right: 25px;
      @include mobile {
        padding-top: 0;
        text-align: center;
        opacity: 1;
        background-color: black;
        align-items: flex-start;
      }

      h1 {
        font-family: "Smooch Sans", sans-serif;
        font-size: 40px;
        opacity: 0.7;
        text-align: center;
        padding-bottom: 30px;
        font-weight: 800;
        @include mobile {
          font-size: 35px;
          padding-left: 20px;
          text-align: center;
        }
      }
      h2 {
        font-family: "Smooch Sans", sans-serif;
        font-size: 30px;
        opacity: 0.7;
        padding-bottom: 30px;
        text-align: center;
        font-weight: 400;
        @include mobile {
          font-size: 22px;
          padding-left: 20px;
          text-align: center;
        }
      }
      h3 {
        font-family: "Smooch Sans", sans-serif;
        font-size: 30px;
        opacity: 0.7;
        text-align: center;
        font-weight: 200;

        @include mobile {
          font-size: 18px;
          padding-left: 20px;
          text-align: center;
        }
      }
    }
  }

  @include smallPhone {
    .left {
      .imgContainer {
        img {
          max-width: 100%;
          max-height: 250px;
          padding: 0;
        }
      }
    }
    .right {
      .desc {
        h1 {
          font-size: 22px;
          padding-top: 0;
          text-align: center;
          padding-bottom: 0;
        }
        h2 {
          font-size: 18px;
          text-align: center;
          padding-bottom: 0;
        }
        h3 {
          text-align: center;
          padding-top: 0;
        }
      }
    }
  }
}
