@import "../../global.scss";

#activenav {
  background-color: black;
}
.navbar {
  top: 35px;
  height: 65px;
  opacity: 1;
  color: white;
  background-color: transparent;
  position: fixed;
  width: 100%;
  z-index: 2;
  transition: all 1s ease;

  .wrapper {
    padding: 0px 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      // background-image: url("../../images/smoke.gif");
      background-repeat: no-repeat;
      background-size: cover;
      background-blend-mode: screen;
      border-radius: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 20px;
      @include mobile {
        padding-left: 5px;
      }
      .logo {
        padding-bottom: 10px;
        transition: all 1s ease;
        a {
          text-decoration: none;
          color: inherit;

          &:hover {
            opacity: 0.8;
            background-image: url("../../images/smoke.gif");
            background-size: cover;
            background-repeat: repeat-y;
          }
          h2 {
            display: inline;
            color: green;
            font-size: 30px;
            font-family: "Rock Salt", cursive;
            opacity: 1;
            -webkit-text-stroke: 0.25px purple;
            cursor: pointer;
            transition: all 2s ease;
            border-radius: 25px;

            @include mobile {
              font-size: 18px;
              padding-top: 15px;
            }
          }

          .first {
            color: purple;
            -webkit-text-stroke: 0.25px green;
          }
        }
        @include mobile {
          padding-top: 20%;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      ul {
        justify-content: space-between;
        a {
          text-decoration: none;
          color: inherit;
          li {
            font-family: "Rock Salt", cursive;
            font-size: 20px;
            opacity: 0.8;
            padding-left: 30px;
            list-style: none;
            display: inline-flex;
            align-items: center;
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  @include mobile {
    .wrapper {
      display: inline-block;
      align-items: center;
      justify-content: space-between;

      .right {
        .hamburger {
          z-index: 500;
          position: absolute;
          top: 10px;
          left: 90%;
          padding-top: 15px;
          width: 32px;
          height: 25px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          cursor: pointer;
          span {
            filter: invert(100);
            height: 3px;
            background-color: black;
            transform-origin: left;
            transition: all 1s ease;
          }
        }
        .links {
          ul {
            a {
              li {
                display: none;
              }
            }
          }
        }
      }
    }
    &.open {
      z-index: 501;
      .logo {
        display: none;
        transition: all 3s ease;
      }

      .hamburger {
        span {
          &:first-child {
            background-color: black;
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            background-color: black;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
