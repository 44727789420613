@import "../../global.scss";

.landing {
  min-height: 100vh;
  background-color: black;
  width: 100%;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  img {
    display: inline-block;
    min-height: 100vh;
    width: 100%;
  }
  .wrapper {
    width: 100%;
    height: 100vh;
    .backg {
      width: 100%;
      height: 100%;
      background-image: url(../../../public/assets/landingbg4.gif);
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 0;
      .heading {
        position: relative;
        right: 20%;
        top: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        .first {
          color: purple;
          -webkit-text-stroke: 0.15px green;
          opacity: 0.6;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        h1 {
          position: relative;
          left: 20%;
          color: green;
          font-family: "Rock Salt", cursive;
          font-size: 85px;
          opacity: 0.7;
          display: flex;
          align-items: center;
          justify-content: center;
          -webkit-text-stroke: 0.15px purple;
          cursor: default;
        }
        .last {
          color: purple;
          -webkit-text-stroke: 0.15px green;
          opacity: 0.6;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
      .headline {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 20%;
        h2 {
          cursor: default;
          align-items: center;
          justify-content: center;
          font-size: 35px;
          color: white;
          opacity: 0.5;
          font-family: "Rock Salt", cursive;
          padding-left: 25px;
        }
      }
      .hours {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        opacity: 0.6;
        text-align: center;
        position: relative;
        top: 20%;

        cursor: default;
        h2 {
          font-family: "Rock Salt", cursive;
        }
        ul {
          li {
            list-style: none;
            font-family: "Rock Salt", cursive;
          }
        }
      }
    }
  }
  @include mobile {
    .wrapper {
      width: 100vw;
      .backg {
        width: 100%;

        .heading {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 30px;
          h1 {
            font-size: 40px;
          }
        }
        .headline {
          display: flex;
          align-items: center;
          justify-content: center;
          h2 {
            font-size: 20px;
            text-align: center;
          }
        }

        .hours {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          h2 {
            font-size: 18px;
          }

          ul {
            li {
              font-size: 14px;
            }
          }
        }
      }
      h1 {
        font-size: 30px;
      }
      span {
        position: relative;
        text-align: center;
        right: 10%;
        font-size: 18px;
      }
    }
  }
}
