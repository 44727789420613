$mainColor: black;

$width: 768px;

@mixin mobile {
  @media (max-width: #{$width}) {
    @content;
  }
}

$lowWidth: 396px;

@mixin smallPhone {
  @media (max-width: #{$lowWidth}) {
    @content;
  }
}
