@import "../../global.scss";

.reviews {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 50px;
    font-family: "Smooch Sans", sans-serif;
    opacity: 0.8;

    @include smallPhone {
      font-size: 35px;
    }
  }
  .container {
    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    width: 100%;
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .card {
      width: 250px;
      height: 70%;
      border-radius: 10px;
      box-shadow: 0px 0px 15px -8px black;
      flex-direction: column;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      &:hover {
        cursor: default;
        transform: scale(1.2);
        transition: all 1s ease;
        opacity: 0.8;
      }

      .top {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-bottom: 5px;
        @include smallPhone {
          font-size: 15px;
          display: flex;
          align-items: center;
          justify-content: space-around;
        }

        svg {
          padding-left: 10px;
          color: gold;
          @include smallPhone {
            font-size: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
        h2 {
          font-size: 30px;
          display: inline;
          padding: 0px 5px;
          height: 100%;
          border-radius: 80%;
          background-color: white;
          color: black;
          @include smallPhone {
            font-size: 15px;
            background-color: whitesmoke;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .center {
        text-align: center;
        background-color: lightgray;
        color: black;
        border-radius: 5px;
        @include smallPhone {
          font-size: 12px;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: center;

        @include smallPhone {
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 10px;
        }
      }
    }
  }
}
