@import "../../global.scss";

.menu {
  display: none;
  @include mobile {
    width: 300px;
    height: 100vh;
    background-color: black;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.75s ease;

    &.open {
      right: 0;
      background-color: $mainColor;
      z-index: 2;
      transition: all 0.75s ease-in-out;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 25px;
      font-weight: 300;
      color: black;
      width: 40%;
      li {
        margin-bottom: 25px;
        a {
          font-size: 20px;
          font-family: "Rock Salt", cursive;
          color: white;
          opacity: 0.7;
          text-decoration: none;
        }
        &:hover {
          font-weight: 500;
          opacity: 0.7;
        }
      }
    }
  }
}
