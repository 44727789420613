@import "../../global.scss";

.products {
  width: 100%;
  max-width: 100vw;
  background-color: black;
  color: white;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  cursor: default;
  @include mobile {
    display: grid;
    align-items: center;
    justify-content: center;
    display: flex;
    max-height: 100vh;
    overflow: hidden;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px;
    font-family: "Smooch Sans", sans-serif;
    font-size: 30px;
    list-style: none;
    &:hover {
      font-size: 32px;
      font-weight: 700;
      transition: all 0.5s ease;
    }
    @include mobile {
      font-size: 1.5rem;
    }
  }

  .left {
    padding-top: 200px;
    @include mobile {
      padding-top: 0;
    }
    flex: 33%;
  }
  .middle {
    flex: 35%;
    align-items: center;
    padding-top: 40px;
  }

  .right {
    padding-top: 200px;
    @include mobile {
      padding-top: 0;
    }
    flex: 33%;
  }
}
