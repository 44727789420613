.app {
  height: 100vh;
  background-color: black;

  .pages {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    > * {
      width: 100%;
      height: calc(100vh - 100px);
      scroll-snap-align: start;
    }
    .gallery {
      height: auto;
      padding: 100px 0;

      margin: 0;
    }
  }
}
