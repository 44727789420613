@import "../../global.scss";

#activebar {
  background-color: black;
}

.contactbar {
  height: 35px;
  top: 0;
  font-size: 20px;
  background-color: transparent;
  color: white;
  opacity: 1;
  position: fixed;
  width: 100%;
  z-index: 2;
  transition: all 1s ease;

  .contactwrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        padding-top: 5px;
        padding-left: 25px;
        padding-right: 20px;
        height: 25px;
      }
      a {
        text-decoration: none;
        color: inherit;
        opacity: 0.8;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    .right {
      padding-right: 15px;
      .locicon {
        font-size: 20px;
        height: 20px;
        padding-top: 5px;
      }
      a {
        text-decoration: none;
        color: white;
        opacity: 0.8;
        &:hover {
          opacity: 0.5;
        }
        span {
          font-family: "Rock Salt", cursive;
          font-size: 20px;
        }
      }
    }
  }
  @include mobile {
    padding-top: 10px;
    .contactwrapper {
      position: relative;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: inline;
        align-items: center;
        justify-content: center;
        .icons {
          display: inline;
        }
        svg {
          justify-content: flex-start;
          padding-left: 15px;
          padding-right: 0px;
        }
        a {
          text-decoration: none;
          color: inherit;
          opacity: 0.8;
          &:hover {
            opacity: 0.5;
          }
        }
      }
      .right {
        display: inline;
        align-items: center;
        justify-content: center;
        padding-right: 5px;

        a {
          svg {
            display: inline;
          }
          span {
            display: inline;
            font-size: 10px;
          }
        }
      }
    }
  }
  @include smallPhone {
    padding-top: 10px;
    .contactwrapper {
      position: relative;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: inline;
        align-items: center;
        justify-content: center;
        .icons {
          padding: 0;
        }
        svg {
          justify-content: flex-start;
          padding-left: 5px;
          padding-right: 0px;
          max-height: 18px;
        }
        a {
          text-decoration: none;
          color: inherit;
          opacity: 0.8;
          &:hover {
            opacity: 0.5;
          }
        }
      }
      .right {
        display: inline;
        align-items: center;
        justify-content: center;
        padding-right: 5px;

        a {
          svg {
            display: inline;
            max-height: 12px;
          }
          span {
            display: inline;
            font-size: 8px;
          }
        }
      }
    }
  }
}
